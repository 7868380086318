import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading /*, Subheading as SubheadingBase*/ } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

//import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

//import SupportIconImage from "images/support-icon.svg";
//import ShieldIconImage from "images/shield-icon.svg";
//import CustomizeIconImage from "images/customize-icon.svg";
//import FastIconImage from "images/fast-icon.svg";
//import ReliableIconImage from "images/reliable-icon.svg";
//import SimpleIconImage from "images/simple-icon.svg";
import { MdFormatListBulleted } from 'react-icons/md';
import { MdSearch } from 'react-icons/md';
import { MdSettings } from 'react-icons/md';
import { MdDownload } from 'react-icons/md';
import { GrDocumentSound } from 'react-icons/gr';
import { FaUserFriends } from 'react-icons/fa';

const Container = styled.div`
  ${tw`relative`}
  page-break-before: always;
`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
//const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  break-inside: avoid;
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0 text-primary-300 text-3xl`}
    img {
      ${tw`w-6 h-6`}
    }
    path, polyline {
      stroke: #1e81b3;
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Amazing Features", subheading = "Features", description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    //{
    //  //imageSrc: ShieldIconImage,
    //  title: "高精度な自動文字起こし",
    //  description: "自動文字起こしには、"
    //},
    //{
    //  //imageSrc: FastIconImage,
    //  title: "使いやすいエディタ",
    //  description: "文字起こし結果に対応する音声を確認しながら修正作業ができるエディタを備えています。"
    //},
    {
      //imageSrc: SupportIconImage,
      icon: <MdFormatListBulleted />,
      title: "単語登録",
      description: "よく使う単語は、単語登録しておくことで、さらに精度高く自動文字起こしすることができます。"
    },
    {
      //imageSrc: SupportIconImage,
      icon: <GrDocumentSound style={{ stroke: '#023373' }} />,
      title: "多彩な入力形式",
      description: "動画・音声ファイルアップロード(WAV、MP3、MP4など)に加え、マイク入力（複数台同時使用可）、PC内部音声の文字起こしに対応。"
    },
    //{
    //  //imageSrc: SupportIconImage,
    //  title: "話者識別",
    //  description: "AIが話者の推定をし、話者情報の入力をサポートします。"
    //},
    //{
    //  //imageSrc: CustomizeIconImage,
    //  title: "ファイルアップロード",
    //  description: "動画・音声ファイルをアップロードし、文字起こしできます。文字起こしが完了した箇所から順次画面表示されますので、サイズの大きなファイルでもあまり待たされません。"
    //},
    //{
    //  //imageSrc: ReliableIconImage,
    //  title: "リアルタイム",
    //  description: "マイクから入力中の音声をリアルタイムに文字起こしします。録音中でも直前に喋った音声とテキストを確認できます。"
    //},
    {
      //imageSrc: SimpleIconImage,
      icon: <MdDownload />,
      title: "ダウンロード",
      description: "文字起こし結果を、CSV、Excel、Word形式で出力できます。タイムスタンプ、話者名、テキストといった出力項目は自由にカスタマイズ可能です。"
    },
    {
      //imageSrc: SupportIconImage,
      icon: <FaUserFriends />,
      title: "同時編集",
      description: "複数のユーザーが同じ文字起こし結果を同時に開くことができるので、協力しながら素早く編集作業を完了できます。"
    },
    {
      //imageSrc: SupportIconImage,
      icon: <MdSearch />,
      title: "検索",
      description: "文字起こし結果に含まれる特定の単語を検索したり、ハイライト表示することが可能です。"
    },
    {
      //imageSrc: SimpleIconImage,
      icon: <MdSettings />,
      title: "ユーザー管理",
      description: "ユーザーを追加し、ユーザーごとにアクセス可能な文字起こし結果を設定することができます。"
    }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {/*subheading && <Subheading>{subheading}</Subheading>*/}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                {/*<img src={card.imageSrc || defaultCardImage} alt="" />*/}
                {card.icon}
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
