import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header, { NavLinks, PrimaryLink } from "components/headers/light.js";
//import Footer from "components/footers/MiniCenteredFooter";
import { useParams } from "react-router-dom";
import ReactLoading from "react-loading";

const Container = tw.div`relative -mx-8 -my-8 h-screen min-h-144`;
const InnerContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = styled.div`
  ${tw`px-4 flex flex-1 flex-col justify-center items-center`}
  a {
    ${tw`border-b-2 border-transparent text-primary-300 hocus:text-primary-300 hocus:border-primary-300 font-black duration-300 mx-1 transition`}
  }
`;

const StyledHeader = tw(Header)`pt-8 max-w-none w-full`;

const Heading = styled.h1`
  ${tw`text-3xl font-black leading-snug -mt-24 sm:mt-0 flex items-center mb-2`}
  span {
    ${tw`inline-block mt-2 ml-2`}
  }
`;

const launchApplication = (fileId) => {
  const link = document.createElement('a');
  link.href = `mojioko://files/${fileId}`;
  link.click();
};

export default () => {
  const { fileId } = useParams();
  useEffect(() => {
    setTimeout(() => {
      launchApplication(fileId);
    }, 300);
  }, [fileId]);

  const headerLinks = [
    <NavLinks key={1}>
      <PrimaryLink css={tw`rounded-full`} href="/download">ダウンロード</PrimaryLink>
    </NavLinks>
  ];

  return (
    <AnimationRevealPage disabled>
      <Container>
        <InnerContainer>
          <StyledHeader links={headerLinks} />
          <Content>
            <Heading>
              <ReactLoading
                type="spin"
                color="#165E83"
                className="mx-auto"
                width="48px"
                height="48px"
              />
              <span>もじおこくんアプリを起動しようとしています。</span>
            </Heading>
            <p>アプリが起動しない場合は、もじおこくんアプリの<a href="/download">ダウンロードおよびインストール</a>を行ってください。</p>
          </Content>
        </InnerContainer>
      </Container>
    </AnimationRevealPage>
  );
};
