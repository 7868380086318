import React, { useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../images/dot-pattern.svg"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { API } from 'aws-amplify';

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  p {
    ${tw`my-2`}
  }
  input[type="text"],input[type="email"],textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
//const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl disabled:bg-primary-300 disabled:text-primary-500 disabled:shadow-none disabled:cursor-not-allowed`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-xl font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl disabled:bg-primary-300 disabled:text-primary-500 disabled:shadow-none disabled:cursor-not-allowed`;
const ErrorMessage = tw.p`mt-2 text-sm text-pink-400 dark:text-pink-400`;
const PrivacyCheckContainer = tw.div`mt-2`
const PrivacyPolicyLink = tw.a`border-b-2 border-transparent text-blue-300 hocus:text-blue-300 hocus:border-blue-300 transition duration-300 mx-1`;
const Checkbox = tw.input`w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`
const CheckboxLabel = tw.label`ml-2`

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`

const REQUIRED_MESSAGE = '必ず入力してください。';
const INVALID_EMAIL_MESSAGE = '正しいメールアドレスを入力してください。';
const MAX_MESSAGE = ({ max }) => `${max}文字以内で入力してください。`;

const formSchema = yup.object({
  name: yup.string().required(REQUIRED_MESSAGE).max(64, MAX_MESSAGE),
  email: yup.string().required(REQUIRED_MESSAGE).email(INVALID_EMAIL_MESSAGE).max(254, MAX_MESSAGE),
  inquiry: yup.string().required(REQUIRED_MESSAGE).max(1000, MAX_MESSAGE),
  confirm: yup.boolean().oneOf([true]).default(false)
});

export default () => {
  const [sending, setSending] = useState(false);
  const [sendErrorMessage, setSendErrorMessage] = useState('');
  const { register, handleSubmit, reset, formState:{ isValid, errors } } = useForm({
    mode: 'onChange',
    resolver: yupResolver(formSchema)
  });
  const onSubmit = async (data) => {
    if (window.confirm('入力いただいた内容で送信してよろしいでしょうか？')) {
      try {
        setSending(true);
        await API.post(
          'mojiokoWebAPI',
          '/inquiry',
          {
            body: {
              name: data.name,
              email: data.email,
              inquiry: data.inquiry
            }
          }
        );
        reset();
      } catch (err) {
        console.error(err);
        setSendErrorMessage('お問い合わせの送信に失敗しました。しばらくしてから再度お試しください。');
      } finally {
        setSending(false);
      }
    }
  };

  return (
    <Container>
      <Content>
        <FormContainer id="contactus" name="contactus">
          <div tw="mx-auto max-w-4xl">
            <h2>お問い合わせ</h2>
            <p>以下のフォームにご入力ください。弊社 もじおこくん 担当よりご連絡させていただきます。</p>
            <p>お問合せに際してご入力頂くお客様の個人情報につきまして、お問合せに対応する目的以外には使用致しません。また第三者などに対しての提供は一切行いません。</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">お名前</Label>
                    <Input id="name-input" type="text" {...register("name")} placeholder="(例) 文字起こし 太郎" />
                    <ErrorMessage>{errors.name?.message}</ErrorMessage>
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">メールアドレス</Label>
                    <Input id="email-input" type="text" {...register("email")} placeholder="(例) mojiokokun@mail.com" />
                    <ErrorMessage>{errors.email?.message}</ErrorMessage>
                  </InputContainer>
                </Column>
                <Column>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="inquiry-input">お問い合わせ内容（1000文字以内）</Label>
                    <TextArea id="inquiry-input" {...register("inquiry")} placeholder="(例) 製品の詳細について聞きたい"/>
                    <ErrorMessage>{errors.inquiry?.message}</ErrorMessage>
                  </InputContainer>
                </Column>
              </TwoColumn>
              <PrivacyCheckContainer>
                <p>
                  弊社の個人情報保護方針は 
                  <PrivacyPolicyLink href="https://www.ndc-net.co.jp/privacy_policy/" target="_blank">こちら</PrivacyPolicyLink>
                   よりご確認ください。
                </p>
                <div className="mt-2">
                  <Checkbox id="privacy-checkbox" type="checkbox" {...register("confirm")} value=""></Checkbox>
                  <CheckboxLabel htmlFor="privacy-checkbox"><b>個人情報の取り扱いについて同意の上送信する</b></CheckboxLabel>
                </div>
              </PrivacyCheckContainer>
              <SubmitButton type="submit" value="送信" disabled={!isValid || sending}>{sending ? '送信中・・・' : '送信'}</SubmitButton>
              <ErrorMessage>{sendErrorMessage}</ErrorMessage>
            </form>
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};
