import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import { SectionHeading } from "components/misc/Headings";

const Container = tw.div`relative -mx-8 -mt-8 px-6 sm:px-8`;
const Content = tw(ContentWithPaddingXl)`max-w-4xl`;
const StyledHeader = tw(Header)`pt-8 max-w-none w-full`;
const BreadcrumbContainer = tw.nav`mt-4 flex`;
const Breadcrumb = tw.ol`inline-flex items-center`;
const BreadcrumbItem = styled.li`
  ${tw`inline-flex items-center`}
  a {
    ${tw`text-primary-200 font-black`}
  }
  &:after {
    content: '>';
    padding: 0 0.5em;
    color: #555;
  }
  &:last-child:after {
    content: '';
  }
`;
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Container>
      <StyledHeader links={[]} />
      <BreadcrumbContainer>
        <Breadcrumb>
          <BreadcrumbItem>
            <a href="/">トップページ</a>
          </BreadcrumbItem>
          <BreadcrumbItem>
            特定商取引法に基づく表記
          </BreadcrumbItem>
        </Breadcrumb>
      </BreadcrumbContainer>
        <Content>
          <HeadingRow>
            <Heading>特定商取引法に基づく表記</Heading>
          </HeadingRow>
          <Text>

            <h3>アプリ名</h3>
            <p>もじおこくん</p>

            <h3>販売業社の名称</h3>
            <p>株式会社日本データコントロール</p>

            <h3>所在地</h3>
            <p>東京都港区高輪 3-25-23 京急第２ビル</p>

            <h3>電話番号</h3>
            <p>03-5791-5011</p>
            <p>受付時間 9:00-17:00 (土日祝を除く)</p>

            <h3>メールアドレス</h3>
            <p>dx-mojiokokun@ndc-net.co.jp</p>

            <h3>運営統括責任者</h3>
            <p>山口 喜久</p>

            <h3>追加手数料等の追加料金</h3>
            <p>サイト閲覧、コンテンツのダウンロード、お問い合わせなどの際に必要となるインターネット接続料金、通信料等。</p>

            <h3>交換および返品（返金ポリシー）</h3>
            <p>電子商品としての性質上、返品/キャンセルできかねます。あらかじめ、提供サービスの内容、動作環境等をよくお確かめの上ご購入ください。</p>
            <p>ただし、明確な不具合が発見された場合は、お問い合わせいただくことでアプリケーションのアップデートまたはその他適切な方法で修正し、提供いたします。</p>

            <h3>引渡時期</h3>
            <p>購入ページに特別の表記がある場合を除き、購入取引完了後直ちにご利用いただけます。</p>

            <h3>受け付け可能な決済手段</h3>
            <p>クレジットカードまたは国内の銀行振込</p>

            <h3>決済期間</h3>
            <p>クレジットカード決済の場合はただちに処理されますが、国内の銀行振込の場合は注文から 30 日以内にお振り込みいただく必要があります。</p>

            <h3>販売価格</h3>
            <p>購入手続きの際に画面に表示されます。 特段の記載の無い限り、消費税は外税として表示しております。</p>
            {/*<ul>
              <li>基本使用量: 10,000円 (税込11,000円)</li>
              <li>同時利用端末数 追加 (1端末毎): 4,000円 (税込4,400円)</li>
              <li>文字起こし時間 追加 (10時間毎): 6,000円 (税込6,600円)</li>
  </ul>*/}

            <h3>ソフトウェアの動作環境</h3>
            <p>Windows 10以降、メモリ容量4GB以上、ディスク空き容量1GB以上</p>
          </Text>
        </Content>
      <Footer />
      </Container>
    </AnimationRevealPage>
  );
};
