import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Modal from "react-modal";
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
Amplify.configure(awsExports);

Modal.setAppElement("#root");

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
