import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
//import logo from "../../images/logo.svg";
//import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
//import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
//import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";


const Container = styled(ContainerBase)`
  ${tw`bg-gray-900 text-gray-100 -mx-8 -mb-8 sticky`}
  top: 100vh;
`;
//const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto py-10 lg:py-12`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

//const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
//const LogoImg = tw.img`w-8`;
//const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const CompanyInfoContainer = styled.div`break-inside: avoid-page;`
const CompanyName = tw.h2`text-2xl my-2 font-black tracking-wider`

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

//const SocialLinksContainer = tw.div`mt-10`;
//const SocialLink = styled.a`
//  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
//  svg {
//    ${tw`w-5 h-5`}
//  }
//`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          {/*<LogoContainer>
            <LogoImg src={logo} />
            <LogoText>もじおこくん</LogoText>
          </LogoContainer>*/}
          <CompanyInfoContainer>
            <CompanyName>運営会社</CompanyName>
            <ul>
              <li>株式会社 日本データコントロール</li>
              <li>〒108-0074</li>
              <li>東京都港区高輪 3-25-23 （京急第２ビル）</li>
              <li>dx-mojiokokun@ndc-net.co.jp</li>
            </ul>
          </CompanyInfoContainer>
          <LinksContainer>
            {/*<Link href="/terms-of-service">利用規約</Link>*/}
            <Link href="/利用規約.pdf" target="_blank">利用規約 (PDF形式)</Link>
            <Link href="https://www.ndc-net.co.jp/privacy_policy/" target="_blank">個人情報保護方針</Link>
            <Link href="/scta">特定商取引法に基づく表記</Link>
            <Link href="https://www.ndc-net.co.jp/" target="_blank">運営会社</Link>
          </LinksContainer>
          <CopyrightText>
            &copy; Nihon Data Control Co.,Ltd.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
