import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import playImageSrc from "images/play.png";
import Modal from 'react-modal';
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Container = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
  page-break-before: always;
`;

const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Image = styled.img``;
const VideoWrapper = tw.div`relative`;
const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.75)',
    zIndex: 100,
  },
  content: {
    border: 0,
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    overflow: 'unset',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
  },
};
const VideoContainer = styled.div`
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
`;
const ResponsiveIFrame = tw.iframe`absolute left-0 top-0 border-0 h-full w-full`;

const CloseButton = styled.button`
  ${tw`absolute focus:outline-none bg-transparent text-white p-0 m-0`}
  top: -40px;
  right: 0;
`;
const PlayButton = styled.button`
  ${tw`absolute flex flex-row items-center font-bold rounded bg-transparent text-white`}
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
`;
const ButtonLabel = styled.div`
  margin-left: 8px;
  white-space: nowrap;
`;

const IntroVideo = ({ heading, description, videoBannerImageSrc, videoSrc }) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => (document.body.style.overflow = 'unset');
  });
  return (
    <Container>
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VideoWrapper>
          <Modal
            isOpen={isOpen}
            ariaHideApp={false}
            style={modalStyle}
            closeTimeoutMS={2000}
            onRequestClose={() => setIsOpen(false)}
          >
            <CloseButton
              onClick={() => setIsOpen(false)}
            >
              <CloseIcon color="white" width={36} height={36} />
            </CloseButton>
            <VideoContainer>
              <ResponsiveIFrame
                src={videoSrc}
                allow="autoplay; fullscreen"
                allowFullScreen
              />
            </VideoContainer>
          </Modal>
          <Image
            src={videoBannerImageSrc}
            alt="video banner"
          />
          <PlayButton
            onClick={() => setIsOpen(true)}
          >
            <Image
              src={playImageSrc}
              alt="Watch Full video"
            />
            <ButtonLabel>Watch Full video</ButtonLabel>
          </PlayButton>
        </VideoWrapper>
    </Container>
  );
};

export default IntroVideo;