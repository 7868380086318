import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent";
import MainFeatures from "components/features/VerticalWithAlternateImageAndText.js";
import SubFeatures from "components/features/ThreeColWithSideImage.js";
import IntroVideo from 'components/features/IntroVideo.js';
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/MojiokoPlan.js";
import FAQ from "components/faqs/SingleCol.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import heroScreenshotImageSrc from "images/hero-screenshot.png";
import videoBannerImageSrc from "images/video-banner.png";
import SimpleContactUs from "components/forms/SimpleContactUs";

const HighlightedText = tw.span`text-primary-300`;

export default () => {

  return (
    <AnimationRevealPage disabled>
      <Hero roundedHeaderButton={true} />
      <MainFeatures />
      <SubFeatures
        subheading={<></>}
        heading={
          <>
            その他の<HighlightedText>特徴</HighlightedText>
          </>
        }
        description={<></>}
      />
      <IntroVideo
        heading={
          <>
            紹介<HighlightedText>動画</HighlightedText>
          </>
        }
        description={<></>}
        videoBannerImageSrc={videoBannerImageSrc}
        videoSrc="https://www.youtube.com/embed/0BCw9cwWC-w?autoplay=1"
      />
      <FeatureWithSteps
        subheading={<></>}
        heading={
          <>
            導入の<HighlightedText>流れ</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={heroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <Pricing
        subheading={<></>}
        heading={<>利用<HighlightedText>料金</HighlightedText></>}
        description={<></>}
      />
      <FAQ
        subheading={<></>}
        heading={
          <>
            よくある<HighlightedText>ご質問</HighlightedText>
          </>
        }
        description={<></>}
        faqs={[
          {
            question: "セキュリティ対策について教えてください",
            answer: <ul>
              <li>
                (1)：通信内容は暗号化しております。TLS/SSL (Transport Layer Security 1.2以上/Secure Socket Layer)(128Bit)による通信の暗号(SSL2.0からTLS1.1までを設定無効(利用不可)とする)
              </li>
              <li>(2)：利用ユーザーの認証情報（パスワード）は暗号化した状態で保持されています。</li>
              <li>(3)：クラウド上の保管データ（音声データ、文字起こし結果）は、暗号化して保管しております。（AES-256bit）</li>
              <li>(4)：保管データについては、管理者をおいて取り扱いをしており、操作ログを記録しております。</li>
              <li>(5)：利用するクラウド音声認識エンジンでは、音声データ等の記録を残さない設定で利用しております。（AmiVoice Cloud、Google Speech-to-Text）</li>
            </ul>
          },
          {
            question: "音声データや文字起こし結果はどこで保管しているのでしょうか。",
            answer:
              "データは、日本国内のサーバに保管しております（AWSの東京リージョンを利用しております）"
          },
          {
            question: "利用するクラウドサービスの準拠法は何でしょうか。",
            answer: (<>
              もじおこくんに関する利用契約の準拠法は、日本国法といたします。<br />
              もじおこくんサービス提供にあたって利用するクラウドサービスとその準拠法は以下の通りです。
              <ul>
                <li>AWS： 日本国法</li>
                <li>AmiVoice Cloud Platform： 日本国法</li>
                <li>Google Cloud： 米国法（カリフォルニア州法）</li>
              </ul>
            </>)
          },
          {
            question: "Web会議の文字起こしはできますか？",
            answer:
              "はい、できます。Web会議システムと同時にもじおこくんを起動し、マイク入力のリアルタイム文字起こし(システムオーディオも含める設定で実行)していただくことで、1台のPCでWeb会議の文字起こしが可能です。また、Web会議システムの録画機能を使って記録した動画・音声ファイルをもじおこくんにアップロードいただくことでも文字起こしできます。"
          },
          {
            question: "自動文字起こしの精度について教えてください。",
            answer:
              "ご利用のマイクや周囲の雑音、マイクと話者の距離によって精度は、大きく変わってきます。ノートパソコン内蔵のマイクではなく、外付けのマイクを利用し、静かな場所で、マイクの近くから話した場合は、9割を超える高い精度での自動文字起こしが可能です。"
          },
          {
            question: "対応OSや実行環境を教えてください。",
            answer:
              <>
                <p>Windows 10以降のPCで実行可能なクライアントアプリをご提供しております。</p>
                <p>現時点では、ブラウザ版やスマートフォン版の提供はございません。</p>
              </>
          },
        ]}
      />
      <SimpleContactUs />
      <Footer />
    </AnimationRevealPage>
  );
}
