import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

import MojiokokunImage from 'images/screenshot.png';
import SpeechToTextImage from 'images/speech-to-text.png';
import EditorImage from 'images/edit.png';
import SpeakerDiarizationImage from 'images/speaker-diarization.png';

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
//const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 max-w-xl`;
const HeadingImage = tw.img`w-full my-4 font-medium max-w-3xl border border-gray-300`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
//const Image = styled.div(props => [
//  `background-image: url("${props.imageSrc}");`,
//  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
//]);
//const Image = styled.div(props => [
//  `background-image: url("${props.imageSrc}");`,
//  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-64 md:h-96 bg-contain bg-center bg-no-repeat mx-4 sm:mx-8 md:mx-4 lg:mx-8`
//]);
const Image = styled.img(props => [
  tw`rounded w-full md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 md:mx-4 lg:mx-8`,
  'max-height: 384px; object-fit: contain;'
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h3`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
//const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

const HighlightedText = tw.span`text-primary-300`;

export default () => {
  const cards = [
    {
      imageSrc: SpeechToTextImage,
      subtitle: "",
      title: "高精度で高速なAI自動文字起こし",
      description: (
        <>
          最先端のAIが音声データを文字起こしします。ユーザーはAIが文字起こしした結果を少し手修正するだけで良いので、これまで文字起こし作業に掛かっていた労力が大きく軽減されます。
        </>
      ),
      //url: "https://timerse.com"
    },

    {
      imageSrc: EditorImage,
      subtitle: "",
      title: "使いやすいエディタ",
      description:
        "音声を聞き返しながら修正作業ができる専用のエディタを備えています。よく使う編集処理にはキーボードショートカットが割り当てられており、活用することで高速な編集が可能です。",
      //url: "https://timerse.com"
    },
    {
      imageSrc: SpeakerDiarizationImage,
      subtitle: "",
      title: "話者識別",
      description:
        "AIが発言箇所ごとに話者の推定を行い、話者情報の入力を支援します。話者ごとの音声を事前に登録しておくといった準備は一切必要ありません。",
      //url: "https://timerse.com"
    },
  ];

  return (
    <Container id="about" name="about">
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle><HighlightedText>もじおこくん</HighlightedText>とは</HeadingTitle>
          <HeadingDescription>
            AIによる自動文字起こしで音声の文字起こし作業を支援するツールです。<br />
            文字起こし結果を編集する専用エディタを備えており、結果の確認・編集も簡単です。<br />
            会議議事録の作成やセミナー、インタビューの文字起こし、動画字幕の作成など、さまざまな場面でご活用いただけます。
          </HeadingDescription>
          <HeadingImage src={MojiokokunImage} alt="もじおこくんアプリのスクリーンショット" />
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              {/*<Image imageSrc={card.imageSrc} />*/}
              <Image src={card.imageSrc} alt={card.title} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/*<Link href={card.url}>See Event Details</Link>*/}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
