import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header, { PrimaryLink } from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import { SectionHeading } from "components/misc/Headings";
import axios from 'axios';
//import dayjs from 'dayjs';
import logo from "../images/logo.svg";

const Container = tw.div`relative -mx-8 -mt-8 px-6 sm:px-8`;
const StyledHeader = tw(Header)`pt-8 max-w-none w-full`;
const BreadcrumbContainer = tw.nav`mt-4 flex`;
const Breadcrumb = tw.ol`inline-flex items-center`;
const BreadcrumbItem = styled.li`
  ${tw`inline-flex items-center`}
  a {
    ${tw`text-primary-200 font-black`}
  }
  &:after {
    content: '>';
    padding: 0 0.5em;
    color: #555;
  }
  &:last-child:after {
    content: '';
  }
`;
const Content = tw(ContentWithPaddingXl)`max-w-4xl`;
const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Subheading = styled.h3`
  ${tw`text-3xl text-gray-900 font-bold mb-8 flex items-center`}

  img {
    ${tw`w-16 mr-3`}
  }
`;
const VersionInfoContainer = styled.div`
  ${tw`mb-8 max-w-96 mt-16 md:mr-12 md:last:mr-0 px-8 py-4 rounded-lg text-gray-900 bg-gray-200`}
  dt {
    ${tw`font-black`}
    float: left;
    clear: both;
    word-break: keep-all;
    overflow-x: hidden;
  }
  dt:after {
    margin: 0 8px 0 4px;
    content: ':';
  }
  dd {
    margin-left: 7rem;
    word-break: keep-all;
    overflow-x: hidden;
  }
`;
const DownloadButtonContainer = tw.div`mt-5 flex flex-wrap`;
const AppDownloadButton = tw(PrimaryLink)`w-full sm:w-auto m-1`
const ManualDownloadButton = tw(PrimaryLink)`w-full sm:w-auto m-1 py-3 bg-gray-100 text-primary-500 rounded-xl font-bold tracking-wide transition duration-300 transform focus:outline-none hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px border-primary-500 border-2 disabled:bg-primary-300 disabled:text-primary-500 disabled:shadow-none disabled:cursor-not-allowed`;


//const formatDateTime = (dateTimeStr) => {
//  const dt = dayjs(dateTimeStr)
//  return dt.format('YYYY/MM/DD')
//};

export default () => {
  const [versionInfo, setVersionInfo] = useState(null);
  useEffect(() => {
    axios.get('https://api.mojiokokun.jp/api/versions/latestclient')
      .then(res => {
        setVersionInfo(res.data);
      });
  }, []);

  return (
    <AnimationRevealPage disabled>
      <Container>
        <StyledHeader links={[]} />
        <BreadcrumbContainer>
          <Breadcrumb>
            <BreadcrumbItem>
              <a href="/">トップページ</a>
            </BreadcrumbItem>
            <BreadcrumbItem>
              ダウンロード
            </BreadcrumbItem>
          </Breadcrumb>
        </BreadcrumbContainer>
        <Content>
          <HeadingRow>
            <Heading>ダウンロード</Heading>
          </HeadingRow>
          <Subheading>
            <img src={logo} alt="logo" />
            もじおこくんクライアントアプリ
          </Subheading>
          <VersionInfoContainer>
            <dl>
              <dt>バージョン</dt><dd>{ versionInfo ? versionInfo.version : '0.0.0' }</dd>
              {/*<dt>更新日時</dt><dd>{ versionInfo ? formatDateTime(versionInfo.releaseDate) : '2022/1/1' }</dd>*/}
              <dt>サイズ</dt><dd>{ versionInfo ? Math.ceil(versionInfo.size / (1024 * 1024)) : '0' } MB</dd>
              <dt>動作環境</dt><dd>Windows 10 以降</dd>
              <dt>メモリ</dt><dd>8 GB以上</dd>
              <dt>ストレージ</dt><dd>2 GB以上の空きスペース</dd>
            </dl>
          </VersionInfoContainer>
          <DownloadButtonContainer>
            <AppDownloadButton href={versionInfo ? versionInfo.url : '#'}>アプリのダウンロード</AppDownloadButton>
            <ManualDownloadButton href="/もじおこくんマニュアル.pdf">操作マニュアルのダウンロード</ManualDownloadButton>
          </DownloadButtonContainer>
        </Content>
        <Footer />
      </Container>
    </AnimationRevealPage>
  );
};
